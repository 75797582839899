import React from "react";
import {
  useToast,
  Box,
  Divider,
  HStack,
  Image,
  Link,
  Stack,
  Button,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

function FeedbackLayout5() {
  const toast = useToast();
  const positions = [
    "top-left",
    "top",
    "top-right",
    "bottom-left",
    "bottom",
    "bottom-right",
  ];

  const statuses = ["success", "error", "warning", "info"];
  // const statusesName = ["Completed", "Cancelled", "Disputed", "Current"];

  const variants = ["solid", "subtle"];
  // const variantsName = ["solid", "subtle"];

  const toastIdRef = React.useRef();
  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }
  // function closeAll() {
  //   toast.closeAll();
  // }
  function addToast() {
    toastIdRef.current = toast({ description: "Create a Handshake" });
  }
  function update() {
    if (toastIdRef.current) {
      toast.update(toastIdRef.current, {
        description: "This is the current Handshake account.",
      });
    }
  }
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Box p={4} display={{ md: "flex" }}>
        <Box flexShrink={0}>
          <Image
            borderRadius="lg"
            maxW="200px"
            width={{ md: 40 }}
            src=" https://bafybeieifivymmpggeju24nw2si7uumcxzarrjjx6l25dc4eaaupuwslzu.ipfs.infura-ipfs.io/"
            // src=" https://bafybeigtzrprfalmfefukvqlwholo4mmjerqavicy6v23z5coyq2wneskm.ipfs.infura-ipfs.io/"
            // src=" https://bafybeibr57ioe2q33kiqko4od2ecih624vxzjn75bmi7hcxpetdvff276e.ipfs.infura-ipfs.io/"
            // src=" https://bafybeih2yx3lez53gt3iswcavitk5qdjvz542dnohtf6pbouwrxwhq5dme.ipfs.infura-ipfs.io/"
            // src=" https://bafybeigycp4qkbhpgk2zv3gvrnmweajn57e6lp4eknvb4ehjiukguxisci.ipfs.infura-ipfs.io/"
            // src=" https://bafybeiheu5fgk7jcfn6k4lywo2prjzo3fajvswvxjtce6nc65a4f3asqta.ipfs.infura-ipfs.io/"
            // src=" https://bafybeibtoxoa5un4zs5cfvhutz4axcdv5alolrjau6asyltmczro7wej2y.ipfs.infura-ipfs.io/"
            // src=" https://bafybeiewni54ty7y56jgak2ke7vdnuioibwtqer5uu5bboxcwp7xnyh4wa.ipfs.infura-ipfs.io/"
            // src=" https://bafybeigaxfpv47tsk7h47nlqbwzxajjfhc4xkxk2tjz3aobvhe5xgjrwve.ipfs.infura-ipfs.io/"
            // src=" https://bafybeie56rvgxfi6fs7yxqckqtye66fbprf7sgf3uo4rrsirwq2iobaumi.ipfs.infura-ipfs.io/"
            // src=" https://bafybeicwlscnwijdnuc4j67bsrdnwigozqbvc5tmhlr23tol67sm3xdade.ipfs.infura-ipfs.io/"
            // src=" https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/"
            // src=" https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/"



            alt=" MetaCollab "
          />
        </Box>

        <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
          <Link
            mt={1}
            display="block"
            fontSize="3xl"
            lineHeight="normal"
            fontWeight="semibold"
            href=" # "
          >
            MetaCollab User-Guide
          </Link>
          <br />
          <Button
            variant="solid"
            color="gray.100"
            colorScheme="green"
            onClick={() =>
              toast({
                position: "bottom-left",
                render: () => (
                  <Box color="white" p={3} bg="blue.500">
                    There are no current gigs.
                  </Box>
                ),
              })
            }
          >
            Query Current Account
          </Button>
          <br />
          <br />
          <Stack isInline spacing={2}>
            <Button
              onClick={addToast}
              type="button"
              colorScheme="green"
              variant="solid"
              color="gray.100"

            >
              Getting started
            </Button>
            <Button
              onClick={close}
              color="gray.100"
              colorScheme="red"
              fontSize="10pt"
              size="sm"
              type="button"
              variant="solid"
            >
              Close note
            </Button>
            {/* <Button
              onClick={closeAll}
              color="red.400"
              fontSize="10pt"
              size="sm"
              type="button"
              variant="solid"
            >
              Close all notes
            </Button> */}
          </Stack>
          <br />
          <Stack isInline spacing={2}>
            <Button
              onClick={addToast}
              type="button"
              colorScheme="cyan"
              variant="solid"
              color="gray.100"

            >
              Query Current Handshake
            </Button>
            <Button
              onClick={update}
              type="button"
              colorScheme="red"
              variant="solid"
              color="gray.100"

            >
              Update current Handshake
            </Button>
          </Stack>

          <Divider></Divider>

          <br />
          <Divider></Divider>
          <br />

          <Box p={4} display={{ md: "flex" }}>
            <HStack>
              <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }}>
                <Link
                  mt={1}
                  display="block"
                  fontSize="2xl"
                  lineHeight="normal"
                  fontWeight="semibold"
                  color="cyan"
                >
                  Non-Disputed Gigs User-Guide
                </Link>
                <br />

                <Wrap>
                  {statuses.map((status, i) => (
                    <WrapItem key={i}>
                      <Button
                        size="md"
                        colorScheme="purple"
                        variant="solid"
                        onClick={() =>
                          toast({
                            title: `${status} Gig status is described here.`,
                            status: status,
                            isClosable: true,
                          })
                        }
                      >
                        Gig is: {status}
                      </Button>
                    </WrapItem>
                  ))}
                </Wrap>

                <Wrap>
                  {variants.map((variant, i) => (
                    <WrapItem key={i}>
                      <Button
                        size="md"
                        colorScheme="blue"
                        variant="solid"
                        onClick={() =>
                          toast({
                            title: `${variant} note`,
                            variant: variant,
                            isClosable: true,
                          })
                        }
                      >
                        Gig is: {variant}
                      </Button>
                    </WrapItem>
                  ))}
                </Wrap>
              </Box>
            </HStack>
          </Box>

          <br />
          <Divider></Divider>

          <Box p={4} display={{ md: "flex" }}>
            <HStack>
              <Box mt={{ base: 4, md: 0 }} ml={{ md: 2 }}>
                <Link
                  mt={1}
                  display="block"
                  fontSize="2xl"
                  lineHeight="normal"
                  fontWeight="semibold"
                  color="cyan"
                >
                  Disputed Gigs User-Guide
                </Link>
                <br />
                <Wrap>
                  {positions.map((position, i) => (
                    <WrapItem key={i}>
                      <Button
                        size="md"
                        colorScheme="orange"
                        variant="solid"
                        onClick={() =>
                          toast({
                            title: `${position} note`,
                            position: position,
                            isClosable: true,
                          })
                        }
                      >
                        Shows in: {position}
                      </Button>
                    </WrapItem>
                  ))}
                </Wrap>
              </Box>
            </HStack>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
export default FeedbackLayout5;
